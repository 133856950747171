// @ts-nocheck
/* eslint-enable */
import queryUtils from 'app/shared/utils/queryUtils';


const logger = getLogger('actions/fetchlisting/controller');

export default {
    handleMaloneChange(apiResponse = {}, { originalUrlWhenMakingApiCall }) {
        let redirectMaloneLotId = apiResponse.message;
        let urlArray = originalUrlWhenMakingApiCall.split('/');
        let addressPieceUrl = urlArray[1];
        let addressPieceArray = addressPieceUrl.split('-');
        addressPieceArray[addressPieceArray.length - 1] = redirectMaloneLotId;
        let newAddressPieceUrl = addressPieceArray.join('-');
        urlArray[1] = newAddressPieceUrl;
        let redirectString = urlArray.join('/');

        logger.warn(
            {
                expectedHttpResponse: 301,
                originalUrl: originalUrlWhenMakingApiCall,
                redirectUrl: redirectString
            },
            'MALONE_CHANGED'
        );

        if (redirectString === originalUrlWhenMakingApiCall) {
            logger.error(
                {
                    expectedHttpResponse: 301,
                    originalUrl: originalUrlWhenMakingApiCall,
                    redirectUrl: redirectString
                },
                'INFINITE_REDIRECT_MALONE_CHANGED'
            );
        }
        return {
            redirect: true,
            to: redirectString,
            changeHttpStatus: 301
        };
    },
    handleUnitChange(apiResponse = {}, { originalUrlWhenMakingApiCall, includedUnit }) {
        let urlArray = originalUrlWhenMakingApiCall.split('/');
        let originalUnit = includedUnit;
        let redirectUnit = queryUtils.parse(apiResponse.message).unit;
        let oldArrayHasUnit = urlArray.length === 4 && originalUnit;

        if (oldArrayHasUnit) {
            urlArray[2] = redirectUnit; // replace unit with new unit
        } else {
            urlArray.splice(2, 0, redirectUnit);
        }

        let redirectString = urlArray.join('/');

        logger.warn(
            {
                expectedHttpResponse: 301,
                originalUrl: originalUrlWhenMakingApiCall,
                redirectUrl: redirectString
            },
            'fetchListingError: UNIT_CHANGED'
        );
        if (redirectString === originalUrlWhenMakingApiCall) {
            logger.error(
                {
                    expectedHttpResponse: 301,
                    originalUrl: originalUrlWhenMakingApiCall,
                    redirectUrl: redirectString
                },
                'fetchListingError: INFINITE_REDIRECT_UNIT_CHANGED'
            );
        }
        return {
            redirect: true,
            to: redirectString,
            changeHttpStatus: 301
        };
    }
};
